body {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
}
:root {
  --numero: 1px
}

.App {
  background-color: #282c34;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
ul li {
  list-style: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  text-decoration: none;
  color: aqua;
}

.buterfly {
  color: var(--btt);
}

@media (max-width: 767px) {
  .paginaMain {
    text-align: center;
    background: #282c34bf !important;
    backdrop-filter: blur(5px);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .irregular {
    background: rgb(0, 0, 0);
    height: 100vh;
    width: 100%;
    clip-path: polygon(0% 100%, 0% 0%, 100% 50%, 100% 100%);
  }
  .texto-pag {
    position: absolute;
    right: 10px;
    color: #61dafb;
    z-index: 1000;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: 
    "a a a a a a a a a a a a"
    "b b b b b b b b b b b b"
    "b b b b b b b b b b b b"
    "b b b b b b b b b b b b"
    "c c c c c c c c c c c c"
    "c c c c c c c c c c c c"
    "c c c c c c c c c c c c"
    ;
  }
  .title-pag {
    grid-area: a;
    text-align: center;
  }
  .img-card {
    grid-area: b;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  .img-pag {
    width: 70%;
    border-radius: 15px;
    box-shadow: -5px 5px 10px rgba(255, 251, 251, 0.516);
  }
  
  .cnt-pag {
    grid-area: c;
    padding-left: 15px;
    color: #61dafb;
    position: relative;
    right: 0px;
  }
  .img-pag:hover {
    transform: scale(1.1);
    border-radius: 2px;
    filter: blur(0);

    -webkit-transition: transform 1.05s ease-in-out, border-radius 1.05s ease-in-out, filter 1.05s ease-in-out;
    -moz-transition:transform 1.05s ease-in-out, margin-left 1.05s ease-in-out;
    -ms-transition:transform 1.05s ease-in-out, margin-left 1.05s ease-in-out;
    

  }
}

@media (min-width: 768px) {
  .paginaMain {
    background-color: #282c34d3 !important;
    backdrop-filter: blur(5px);
    /* background: url(../public/img/rikmorty.jpg) no-repeat center fixed; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    /* border: 1px solid white; */
  }
  .irregular {
    background: #000;
    height: 100vh;
    width: 100%;
    clip-path: polygon(15% 100%, 40% 0%, 100% 0%, 100% 100%);
  }
  .texto-pag {
    position: absolute;
    right: 10px;
    color: #61dafb;
    z-index: 1000;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: 
    "a a a a a a b b b b b b"
    "a a a a a a b b b b b b"
    ;
  }
  .img-card {
    grid-area: a;
    padding: 15px;
    margin: auto;
  }
  .img-pag {
    max-height: 75vh;
    width: 95%;
    border-radius: 15px;
    box-shadow: 5px 5px 10px rgba(255, 251, 251, 0.516);
    z-index: 10000000000;
    /* filter: blur(1px);crea un desenfoque gaussiano en la imagen */
  }
  .img-pag:hover {
    transform: scale(1.1);
    margin-left: 40px;
    border-radius: 2px;
    filter: blur(0);

    -webkit-transition: transform 1.05s ease-in-out, margin-left 1.05s ease-in-out, border-radius 1.05s ease-in-out, filter 1.05s ease-in-out;
    -moz-transition:transform 1.05s ease-in-out, margin-left 1.05s ease-in-out;
    -ms-transition:transform 1.05s ease-in-out, margin-left 1.05s ease-in-out;
    

  }
  .cnt-pag {
    grid-area: b;
    padding-left: 75px;
    padding-right: 15px;
    color: #61dafb;
    position: relative;
    text-align: right;
  }
}

.font-text {
  font-size: medium !important;
}

.font-release {
  font-size: medium !important;
  color: #848485;
}

.paginaMain_logos {
  text-align: center;
  background: #282c34bf !important;
  backdrop-filter: blur(3px);
  min-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: calc(7px + 2vmin);
  color: white;
}
#card_logo{
  background-color: #494949;
  border: 1px solid rgb(7, 5, 52);
  width: 180px;
  height: 120px;
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.108);
  /* animacion */
  transition: box-shadow .1s, transform;
}

#card_logo:hover {
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 1);
  z-index: 100000;
}
